/* You can add global styles to this file, and also import other style files */
html, body { min-height: 100vh; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@import '~@angular/material/theming';
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();
@import 'palettes.scss';
// // Define the palettes for your theme using the Material Design palettes available in palette.scss
// // (imported above). For each palette, you can optionally specify a default, lighter, and darker
// // hue. Available color palettes: https://material.io/design/color/
$app-primary: mat-palette($primary-palette, 600);
$app-accent:  mat-palette($accent-palette, 200);
$app-warn:    mat-palette($mat-red);
$app-success: mat-palette($mat-light-green);

// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);
@include angular-material-theme($app-theme);
$primary: map-get($app-theme, primary);
$accent: map-get($app-theme, accent);

:root {
  --color-fill: #{mat-color(mat-palette($fill-palette, 800))};
  --color-dark-text: black;
  --color-light-text:white;
  --color-primary: #{mat-color($app-primary)};
  --color-accent: #{mat-color($app-accent)};
  --color-warn: #{mat-color($app-warn)};
  --color-success: #{mat-color($app-success)};
}

html{
  background-color: var(--color-fill);
}

mat-card-title, mat-card-subtitle, button, h1, h2, h3, h4, a{
  color: var(--color-dark-text);
}

a{
  color: var(--color-dark-text);
}

h3 {
  font-size: x-large;
  font-weight: 500;
}